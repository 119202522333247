import { ICoreContext } from '@msdyn365-commerce/core';
import { SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import { Address, Cart, CartLine, Coupon, DiscountLine, SalesLine } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { sha256 } from 'js-sha256';

import { PageType } from './types'

export const getPageType = (context: ICoreContext): PageType => {
	const pageName = context.actionContext.requestContext.telemetryPageName

    const currentURLPathName = context.request.url.requestUrl.pathname.replace(/\/$/, '');
    const defaultRoutes = context.request.app?.routes;

	// TODO: fix
    if (!defaultRoutes) {
        return PageType.PAGE_TYPE_NOT_LOADED;
    }

	const conditions: { [key: string]: PageType } = {
		[String(pageName?.toLowerCase().includes('sign-in'))]: PageType.LOGIN,
		[String(pageName?.toLowerCase().includes('sign-up'))]: PageType.SIGN_UP_ECOM,
		[String(defaultRoutes.home?.destinationUrl?.replace(/\/$/, '') === currentURLPathName)]: PageType.HOME,
		[String(currentURLPathName.endsWith('.c'))]: PageType.LISTING,
		[String(currentURLPathName.endsWith('.p'))]: PageType.PDP,
		[String(currentURLPathName.includes('/blog'))]: PageType.BLOG,
		[String(defaultRoutes.search?.destinationUrl?.replace(/\/$/, '') === currentURLPathName)]: PageType.SEARCH_RESULTS,
		[String(defaultRoutes.cart?.destinationUrl?.replace(/\/$/, '') === currentURLPathName)]: PageType.CART,
		[String(defaultRoutes.checkout?.destinationUrl?.replace(/\/$/, '') === currentURLPathName)]: PageType.CHECKOUT,
		[String(defaultRoutes.orderConfirmation?.destinationUrl?.replace(/\/$/, '') === currentURLPathName)]: PageType.TYP,
		[String(defaultRoutes.account?.destinationUrl?.replace(/\/$/, '') === currentURLPathName)]: PageType.MY_ACCOUNT,
		[String(defaultRoutes.accountProfile?.destinationUrl?.replace(/\/$/, '') === currentURLPathName)]: PageType.MY_PROFILE,
		[String(defaultRoutes.loyalty?.destinationUrl?.replace(/\/$/, '') === currentURLPathName)]: PageType.LOYALTY,
		[String(defaultRoutes.wishlist?.destinationUrl?.replace(/\/$/, '') === currentURLPathName)]: PageType.WISHLIST,
	};

	const matchedCondition = Object.keys(conditions).find(condition => condition === 'true');

	return matchedCondition ? conditions[matchedCondition] : PageType.OTHER;
}

export const saveCurrentCategoryHierarchyAndListName = (
	context: ICoreContext,
	categoryHierarchy: string,
	listName: string
): void => {
	sessionStorage.setItem('venchiProductCategoryHierarchy', categoryHierarchy ? categoryHierarchy : getPageType(context))
    sessionStorage.setItem('venchiProductListName', listName)
}

export const saveAddToCartCategoryHierarchyAndListName = (
	context: ICoreContext, categoryHierarchy?: string, listName?: string
): void => {
	const receivedCategory = categoryHierarchy ? categoryHierarchy : getPageType(context);
	sessionStorage.setItem('venchiAddToCartProductCategoryHierarchy', (receivedCategory ? receivedCategory : ''));
    sessionStorage.setItem('venchiAddToCartProductListName', (listName ? listName : ''));
}

export const refreshAddToCartCategoryHierarchyAndListName = (): void => {
	var _catHierarchy = sessionStorage.getItem('venchiProductCategoryHierarchy');
	var _listName = sessionStorage.getItem('venchiProductListName');
	sessionStorage.setItem('venchiAddToCartProductCategoryHierarchy',  _catHierarchy || '');
    sessionStorage.setItem('venchiAddToCartProductListName', _listName || '');
}

export const getAddToCartCategoryHierarchyAndListName = (): {categoryHierarchy: string, listname: string} => {
	var categoryHierarchy = sessionStorage.getItem('venchiAddToCartProductCategoryHierarchy') || '';
	var listname = sessionStorage.getItem('venchiAddToCartProductListName') || '';
	return {categoryHierarchy, listname}
}

export const extractCategoryNames = (categories: string): string[] =>{
	// Use map to transform each category into its name, filtering out undefined names
	return categories.split('|',5).concat(Array(5).fill('')).slice(0, 5);
}

export const getCurrentCategoryHierarchyAndListName = () : { categoryHierarchy: string[] | null, listName: string } => {
	var _catHierarchy = sessionStorage.getItem('venchiProductCategoryHierarchy');
	var _catHierarchyArray : string[] | null = _catHierarchy ? extractCategoryNames(_catHierarchy) : null;
	var _listName = sessionStorage.getItem('venchiProductListName');

	return {categoryHierarchy: _catHierarchyArray, listName: _listName ?? ''}
}

export const getCategoryPageString = (): string => {
	return 'Listing';
}

export const getSearchPageString = (): string => {
	return 'Search Results';
}

export const isCategoryPage = (context: ICoreContext): boolean => {
	const currentURLPathName = context.request.url.requestUrl.pathname.replace(/\/$/, '');
	return (currentURLPathName.endsWith('.c'));
}

export const getListIdFromListName = (listName: string): string => {
    const cleanListName = listName.replace(/[^\p{L}|\d\s]/gu, '');
    return cleanListName.toLowerCase().replace(/\s/g, '_');
}

const getCurrentCategoryHierarchyAndListNameFromCartLine = (
	cartLine: CartLine
) : { categoryHierarchy: string[] | null, listName: string } => {
	if (!cartLine.ExtensionProperties?.length) {
		return {
			categoryHierarchy: null,
			listName: ''
		}
	}

	let _catHierarchy = cartLine.ExtensionProperties.filter(e => e.Key === 'venchiProductCategoryHierarchy')[0]?.Value?.StringValue;
	let _catHierarchyArray : string[] | null = _catHierarchy ? extractCategoryNames(_catHierarchy) : null;
	let _listName = cartLine.ExtensionProperties.filter(e => e.Key === 'venchiProductListName')[0]?.Value?.StringValue;

	return {categoryHierarchy: _catHierarchyArray, listName: _listName ?? ''}
}

const getCouponFromCart = (
	coupons: Coupon[],
	discountLine: DiscountLine
): string => {
	if (!discountLine) return ''

	const coupon = coupons.find(c => c.DiscountOfferId === discountLine.OfferId);

	return coupon?.Code || '';
}

export const getItemFromSimpleProduct = (
	product: SimpleProduct,
	categories: string[],
	listName: string,
	index: number,
	quantity: number,
	currency: string,
) => {
	return {
		item_id: product.ItemId,
		item_name: product.Name,
		affiliation: 'Venchi',
		currency,
		coupon: '',
		discount: Number((product.BasePrice - product.Price).toFixed(2)) * quantity,
		index,
		item_brand: 'Venchi',
		item_category: categories[0],
		item_category2: categories[1],
		item_category3: categories[2],
		item_category4: categories[3],
		item_category5: categories[4],
        item_list_id: getListIdFromListName(listName),
		item_list_name: listName,
		item_variant: '',
		location_id: '',
		price: Number(product.BasePrice.toFixed(2)) * quantity,
		quantity,
	}
}

export const getItemFromCartLine = (
	cartLine: CartLine,
	productName: string,
	coupons: Coupon[],
	index: number,
	currency: string,
) => {
	const {
		categoryHierarchy,
		listName
	} = getCurrentCategoryHierarchyAndListNameFromCartLine(cartLine)
    const categories = categoryHierarchy ? categoryHierarchy : ['', '', '', '', ''];
	
	let couponCodes = ''; // TODO: check multiple coupons
	if (cartLine?.DiscountLines) {
		couponCodes = cartLine.DiscountLines.filter(d => d.EffectiveAmount && d.EffectiveAmount > 0.01).map(
			discountLine => getCouponFromCart(coupons, discountLine)
		).join('_');
	}

	return {
		item_id: cartLine.ItemId,
		item_name: productName,
		affiliation: 'Venchi',
		currency,
		coupon: couponCodes,
		discount: Number(cartLine.DiscountAmount?.toFixed(2) || 0),
		index,
		item_brand: 'Venchi',
		item_category: categories[0],
		item_category2: categories[1],
		item_category3: categories[2],
		item_category4: categories[3],
		item_category5: categories[4],
        item_list_id: getListIdFromListName(listName),
		item_list_name: listName,
		item_variant: '',
		location_id: '',
		price: Number(((cartLine.Price || 0) * (cartLine.Quantity || 1)).toFixed(2)),
		quantity: cartLine.Quantity,
	}
}

export const getItemFromSalesLine = (
	salesLine: SalesLine,
	productName: string,
	index: number,
	currency: string
) => {
	//TODO update this part
	// const {
	// 	categoryHierarchy,
	// 	listName
	// } = getCurrentCategoryHierarchyAndListNameFromCartLine(salesLine) 
	
	const categoryHierarchy = ['', '', '', '', ''];
	const listName = '';
    const categories = categoryHierarchy ? categoryHierarchy : ['', '', '', '', ''];

	let couponCodes = ''; // TODO: check multiple coupons
	if (salesLine?.DiscountLines) {
		couponCodes = salesLine.DiscountLines.filter(d => d.EffectiveAmount && d.EffectiveAmount > 0.01 && d.DiscountCode).map(
			discountLine => discountLine.DiscountCode
		).join('_');
	}

	return {
		item_id: salesLine.ItemId,
		item_name: productName,
		affiliation: 'Venchi',
		currency,
		coupon: couponCodes,
		discount: Number(salesLine.DiscountAmount?.toFixed(2) || 0),
		index,
		item_brand: 'Venchi',
		item_category: categories[0],
		item_category2: categories[1],
		item_category3: categories[2],
		item_category4: categories[3],
		item_category5: categories[4],
        item_list_id: getListIdFromListName(listName),
		item_list_name: listName,
		item_variant: '',
		location_id: '',
		price: Number(salesLine.GrossAmount?.toFixed(2) || 0),
		quantity: salesLine.Quantity,
	}
}
export const setOrderPlacedStatusLocalStorage = (status: Boolean): void => {
    localStorage.setItem('venchiOrderPlaced', JSON.stringify(status))
}

export const getOrderPlacedStatusLocalStorage = () : Boolean => {
    var statusString = localStorage.getItem('venchiOrderPlaced') || 'false'
	return JSON.parse(statusString)
}

export const hashSHA256 = (message: string): string => {
	return sha256.hex(message) // Use 'hex' method for hex string output
}


export const setCheckoutCartInLocalStorage = (cart: Cart | undefined): void => {
    if (!cart) {
		localStorage.removeItem('venchiCheckoutCart')
	} else {
		localStorage.setItem('venchiCheckoutCart', JSON.stringify(cart))
	}
}

export const getCheckoutCartFromLocalStorage = (): Cart | null => {
    var cartString = localStorage.getItem('venchiCheckoutCart')

	if (!cartString) {
		return null
	} else {
		return JSON.parse(cartString) as Cart
	}
}

export const getUserDataForTracking = (userData: any, DLObject: any) => {
	// All other events
	if (!DLObject.shouldTrackShippingAddress) {
		return userData
	}

	// Purchase event
	return {
		...userData,
		address: {
			first_name: '',
			last_name: '',
			...DLObject.shippingAddress
		}
	}
}

export const getUserPrimaryAddress = (addresses: Address[] | undefined) => {
    if (!addresses) {
        return {
            street: '',
            city: '',
            region: '',
            country: '',
            postal_code: '',
        }
    }
 
    const address =  addresses.find(a => a.IsPrimary)
 
    return address ? {
        street: address.Street || '',
        city: address.City || '',
        region: address.County || '',
        country: address.ThreeLetterISORegionName || '',
        postal_code: address.ZipCode || '',
    } : {
        street: '',
        city: '',
        region: '',
        country: '',
        postal_code: '',
    }
}
